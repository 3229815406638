import { ref } from 'vue'

export const checkboxList: any = ref([
  {
    title: '默认',
    value: ['cnyAmount', 'cnyRevenue', 'orderAmount'],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '销售额(USD)',
        dataIndex: 'usdAmount',
        key: 'usdAmount',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '总收入(USD)',
        dataIndex: 'usdRevenue',
        key: 'usdRevenue',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '销售额(CNY)',
        dataIndex: 'cnyAmount',
        key: 'cnyAmount',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '总收入(CNY)',
        dataIndex: 'cnyRevenue',
        key: 'cnyRevenue',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '下载量',
        dataIndex: 'orderAmount',
        key: 'orderAmount',
        sorter: true,
        width: 100,
        checked: false,
      },
    ],
  },
  {
    title: '退费',
    value: [],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '退费金额(CNY)',
        dataIndex: 'cnyRefundAmount',
        key: 'cnyRefundAmount',
        sorter: true,
        width: 140,
        checked: false,
      },
      {
        title: '退费金额(USD)',
        dataIndex: 'usdRefundAmount',
        key: 'usdRefundAmount',
        sorter: true,
        width: 140,
        checked: false,
      },
      {
        title: '退费订单数',
        dataIndex: 'refundOrderAmount',
        key: 'refundOrderAmount',
        sorter: true,
        width: 140,
        checked: false,
      },
    ],
  },
])

export const basicColumns: any = [
  {
    title: '时间',
    dataIndex: 'dt',
    key: 'dt',
    sorter: true,
    fixed: 'left',
  },
  {
    title: '产品名',
    dataIndex: 'appName',
    key: 'appName',
    fixed: 'left',
  },
  {
    title: '包名',
    dataIndex: 'pkg',
    key: 'pkg',
  },
  {
    title: '支付渠道',
    dataIndex: 'payChnl',
    key: 'payChnl',
  },
  {
    title: '国家',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: '销售额(USD)',
    dataIndex: 'usdAmount',
    key: 'usdAmount',
    // sorter: true,
  },
  {
    title: '总收入(USD)',
    dataIndex: 'usdRevenue',
    key: 'usdRevenue',
    // sorter: true,
  },
  {
    title: '销售额(CNY)',
    dataIndex: 'cnyAmount',
    key: 'cnyAmount',
    sorter: true,
  },
  {
    title: '总收入(CNY)',
    dataIndex: 'cnyRevenue',
    key: 'cnyRevenue',
    sorter: true,
  },
  {
    title: '总订单',
    dataIndex: 'orderCount',
    key: 'orderCount',
  },
  {
    title: '退费金额(USD)',
    dataIndex: 'usdRefundAmount',
    key: 'usdRefundAmount',
  },
  {
    title: '退费金额(CNY)',
    dataIndex: 'cnyRefundAmount',
    key: 'cnyRefundAmount',
  },
  {
    title: '退费订单数',
    dataIndex: 'refundOrderAmount',
    key: 'refundOrderAmount',
  },
  {
    title: '下载量',
    dataIndex: 'orderAmount',
    key: 'orderAmount',
  },
]
