import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d68d1ba6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "filter-column"
};
const _hoisted_2 = {
  class: "allCheck"
};
const _hoisted_3 = {
  class: "group-check"
};
const _hoisted_4 = {
  class: "input-warp"
};
const _hoisted_5 = {
  key: 0,
  class: "item-label"
};
const _hoisted_6 = {
  key: 1
};
import useCheckbox from "../hooks/useCheckbox";
import { checkboxList } from "./data";
export default {
  __name: 'FilterColumn',
  emits: ["getColumn"],
  setup(__props, {
    emit
  }) {
    const {
      showCheckList,
      checkboxList: tempList,
      isCheckAll,
      isIndeterminate,
      onCheckAllChange,
      handleChange,
      handleAll
    } = useCheckbox(emit, 1, checkboxList.value);
    // defineExpose({getColumn})

    return (_ctx, _cache) => {
      const _component_a_checkbox = _resolveComponent("a-checkbox");
      const _component_a_checkbox_group = _resolveComponent("a-checkbox-group");
      const _component_a_popover = _resolveComponent("a-popover");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_popover, {
        trigger: "click",
        placement: "bottomRight",
        overlayClassName: "check-popover"
      }, {
        content: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_a_checkbox, {
          checked: _unref(isCheckAll),
          "onUpdate:checked": _cache[0] || (_cache[0] = $event => _isRef(isCheckAll) ? isCheckAll.value = $event : null),
          indeterminate: _unref(isIndeterminate),
          onChange: _unref(handleAll)
        }, {
          default: _withCtx(() => [_createTextVNode("全选")]),
          _: 1
        }, 8, ["checked", "indeterminate", "onChange"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tempList), group => {
          return _openBlock(), _createElementBlock("div", {
            key: group.title,
            class: "item-check"
          }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_a_checkbox, {
            checked: group.checkAll,
            "onUpdate:checked": $event => group.checkAll = $event,
            indeterminate: group.indeterminate,
            onChange: $event => _unref(onCheckAllChange)($event, group)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(group.title), 1)]),
            _: 2
          }, 1032, ["checked", "onUpdate:checked", "indeterminate", "onChange"])]), _createVNode(_component_a_checkbox_group, {
            value: group.value,
            "onUpdate:value": $event => group.value = $event,
            class: "item-check-option"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.children, item => {
              return _openBlock(), _createBlock(_component_a_checkbox, {
                key: item.key,
                value: item.key,
                onChange: $event => _unref(handleChange)($event, group)
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
                _: 2
              }, 1032, ["value", "onChange"]);
            }), 128))]),
            _: 2
          }, 1032, ["value", "onUpdate:value"])]);
        }), 128))]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_unref(showCheckList).length ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(showCheckList)[0]), 1)) : _createCommentVNode("", true), _unref(showCheckList).length > 1 ? (_openBlock(), _createElementBlock("span", _hoisted_6, "+" + _toDisplayString(_unref(showCheckList).length - 1), 1)) : _createCommentVNode("", true)])])]),
        _: 1
      })]);
    };
  }
};